import Autocomplete from '@tarekraafat/autocomplete.js/dist/js/autoComplete';
import Axios from 'axios';
import { fetchData } from '../../helpers/base_data_fetcher';
import AutocompleterSettings from './autocompleter_settings';

let minCharsSearch = 0;
const settings = new AutocompleterSettings();
const autocompleter = {
  init: (options) => {
    if (options.input) {
      fetchData('site.autocomplete_min_chars').then((autoCompleteMinChars) => {
        minCharsSearch = autoCompleteMinChars;
        autocompleter.configureDefaults(options);
        autocompleter.callAutocomplete(
          options.input,
          options.searchHistory,
          options.form,
        );
      });
    } else {
      console.log(
        'Autcompleter failed to initialise because no input field had been set',
      );
    }
  },

  configureDefaults: (options) => {
    autocompleter.urlData.queryString = {
      ...autocompleter.urlData.queryString,
      ...options.queryString,
    };
    autocompleter.urlData.settings = {
      ...autocompleter.urlData.settings,
      ...options.settings,
    };
    if (options.url) {
      autocompleter.urlData.url = options.url;
    }
  },

  urlData: settings.currentSettings,

  charsAllowed: (value) => (regexTest) => regexTest.test(value),

  getCurrentSelectedListElement: () =>
    [].slice
      .call(document.getElementsByClassName('autoComplete_result'))
      .find((resultNode) => /_selected/.test(resultNode.className)),

  addCleanUpOnblurListener: (input) => {
    input.onblur = () => {
      const resultList = document.querySelectorAll('.autoComplete_list');
      resultList.forEach((list) => {
        if (list.childNodes.length != 0) {
          [].slice
            .call(list.childNodes)
            .forEach((node) => list.removeChild(node));
        }
      });
    };
  },

  addResetSelectionListener: (input) => {
    input.addEventListener('keydown', (key) => {
      const active = autocompleter.getCurrentSelectedListElement();
      if (active && (key.code == 'ArrowDown' || key.code == 'ArrowUp')) {
        active.classList.remove('autoComplete_selected');
      }
    });
  },

  callAutocomplete: (input, searchHistory, form) => {
    autocompleter.addCleanUpOnblurListener(input);
    autocompleter.addResetSelectionListener(input);
    new Autocomplete({
      data: {
        src: async () => {
          autocompleter.urlData.queryString.q = input.value.toLowerCase();
          const CancelToken = Axios.CancelToken.source();

          if (input.value.length < minCharsSearch) {
            if (searchHistory) {
              const formattedHistory = searchHistory.map((historyItem) => ({
                ...historyItem,
                isSearchHistory: true,
              }));
              return Promise.resolve(formattedHistory);
            }
            return Promise.resolve([]);
          }

          try {
            CancelToken.cancel();
          } catch (e) {
            throw new Error(e);
          }
          const result = Axios.get(autocompleter.urlData.url, {
            params: { ...autocompleter.urlData.queryString },
            cancelToken: CancelToken.source,
          })
            .then((data) => {
              if (data.data == '') {
                return null;
              }
              const list = data.data.list.map((address) => {
                if (
                  searchHistory &&
                  searchHistory.find(
                    (historyItem) =>
                      historyItem.search_string == address.search_string,
                  )
                ) {
                  address.isSearchHistory = true;
                }
                return address;
              });
              return list;
            })
            .catch((err) => {
              throw new Error(err);
            });

          return result;
        },
        cache: false,
        key: ['display_name'],
      },
      sort: (a, b) => {
        if (a.value.isSearchHistory && !b.value.isSearchHistory) return -1;
        if (!a.value.isSearchHistory > b.value.isSearchHistory) return 1;
        return 0;
      },
      selector: () => {
        return form.querySelector('[data-search-by-location]');
      },
      threshold: 0,
      resultsList: {
        render: true,
        container: (source) => {
          source.setAttribute('class', 'autoComplete_list');
        },
        destination: form.querySelector('[data-search-by-location]'),
        position: 'afterend',
        element: 'div',
      },
      maxResults: 100,
      highlight: true,
      resultItem: {
        content: (data, source) => {
          if (data.value.isSearchHistory) {
            source.classList.add('history');
          }
          source.innerHTML = data.match;
          source.addEventListener('mousemove', autocompleter.selectOnlyOne);
        },
        element: 'div',
      },
      onSelection: (feedback) => {
        input.value = feedback.selection.value.search_string;
      },
      debounce: autocompleter.urlData.settings.delay,
    });
  },
  selectOnlyOne: (event) => {
    const active = autocompleter.getCurrentSelectedListElement();
    if (!active) {
      event.target.classList.add('autoComplete_selected');
    } else if (active && event.target && event.target != active) {
      active.classList.remove('autoComplete_selected');
      event.target.classList.add('autoComplete_selected');
    }
  },
};

export default autocompleter;
