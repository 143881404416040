import { format } from 'date-fns';
import { fr, enGB } from 'date-fns/locale';
import distanceBetweenDatesInWords from '../../helpers/distance_between_dates_in_words';
import distanceBetweenDatesInWordsFr from '../../../../appartager/helpers/distance_between_dates_in_words_fr';
import {
  formatMinToMaxTermText,
  valInYearsOrMonths,
} from '../../updateProfileModal/helpers/term_formatting';

const getMonth = (dateString, formatString, siteId, available) => {
  if (dateString && dateIsInPast(dateString)) {
    return false;
  }

  if (dateString) {
    const dateValues = dateString.split('-');
    const formattedDate = format(
      new Date(
        parseInt(dateValues[0], 10),
        parseInt(dateValues[1], 10) - 1,
        parseInt(dateValues[2], 10),
      ),
      formatString,
      { locale: siteId === 21 ? fr : enGB },
    );

    return `${available || ''}${available ? ' ' : ''}${formattedDate}`;
  }

  return false;
};

const calculateLastActiveText = function(datestamp, siteId) {
  const last_active_date = new Date(Date.parse(datestamp));
  if (siteId === 21) {
    return distanceBetweenDatesInWordsFr(last_active_date, Date.now());
  }
  return distanceBetweenDatesInWords(last_active_date, Date.now());
};

function dateIsInPast(date) {
  const [year, month, day] = date.split('-');
  const today = new Date();

  const dateInput = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10),
  );

  return dateInput.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
}

const formatMoveDate = (term_preferences) => {
  const { date_available } = term_preferences;
  if (date_available === 'asap') {
    return 'ASAP';
  }

  if (date_available && dateIsInPast(date_available)) {
    return 'available_now';
  }

  if (date_available) {
    const dateValues = date_available.split('-');
    return format(
      new Date(
        parseInt(dateValues[0], 10),
        parseInt(dateValues[1], 10) - 1,
        parseInt(dateValues[2], 10),
      ),
      'do MMM yyyy',
    );
  }

  return false;
};

const formatTerm = (term_preferences) => {
  let term = '';
  const { min_term, max_term } = term_preferences;

  if (!min_term && !max_term) {
    return false;
  }

  if (min_term && max_term) {
    term = formatMinToMaxTermText(min_term, max_term);
  } else if (max_term) {
    term = `Up to ${valInYearsOrMonths(max_term)}`;
  } else if (min_term) {
    term = `${valInYearsOrMonths(min_term)}+`;
  } else {
    return false;
  }

  return term;
};

const verifiedBy = (verified_by) => {
  if (!verified_by) {
    return false;
  }

  if (verified_by.length > 1) {
    return 'id_and_background_check';
  }

  return 'id_check';
};

const createProfile = (data, siteId) => {
  const { user, settings } = data;
  const seeker =
    user.user_type.looking_for_a_share || user.user_type.make_a_share;
  const profile = {
    age: user.age,
    gender: user.gender !== 'other' ? user.gender : '',
    occupation:
      seeker ||
      (user.advertiser_role !== 'agent' &&
        user.advertiser_role !== 'live out landlord')
        ? user.occupation
        : '',
    move_date: seeker ? formatMoveDate(user.term_preferences) : '',
    term: seeker ? formatTerm(user.term_preferences) : '',
    member_since: user.member_since ? user.member_since.substring(0, 4) : '',
    verified_by: verifiedBy(user.verified_by),
    last_active: user.last_active
      ? calculateLastActiveText(user.last_active, siteId)
      : '',
  };

  if (!seeker && user.user_type.have_a_share) {
    delete profile.move_date;
    delete profile.term;
    if (
      user.advertiser_role === 'agent' ||
      user.advertiser_role === 'live out landlord'
    ) {
      delete profile.occupation;
    }
  }

  if (settings?.hide_age) {
    delete profile.age;
  }

  /** less fields shown for Appartager */
  if (siteId === 21) {
    delete profile.occupation;
    delete profile.move_date;
    delete profile.term;
    delete profile.age;
  }

  return profile;
};

export {
  getMonth,
  calculateLastActiveText,
  formatMoveDate,
  formatTerm,
  createProfile,
};
