import Axios from 'axios';
import get from '../../helpers/data_layer/get';

export default class Geo {
  constructor() {
    this.getPostcode = () => {
      if ('geolocation' in navigator) {
        return this.getCurrentPosition()
          .then(position => this.getPostcodeFromPosition(position))
          .catch(error => Promise.reject(error));
      }
      return Promise.reject(
        new Error('Geolocation is not supported by your browser.'),
      );
    };

    this.latLonToPostcode = (lat, lon) => Axios.get(`${get('site.base_url')}/point_to_postcode_sector.pl`, {
      params: { latitude: lat, longitude: lon },
    });

    this.getCurrentPosition = () => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(
      position => resolve(position),
      () => reject(new Error('We could not get your current position.')),
      {
        timeout: 15000,
      },
    ),
    );

    this.getPostcodeFromPosition = position => this.latLonToPostcode(position.coords.latitude, position.coords.longitude)
      .then((data) => {
        if (data.data.success === 1) {
          return data.data.postcode_sector;
        }
        throw new Error('We could not find your location.');
      })
      .catch(() => {
        // Generic message to pass on to user
        throw new Error('We could not find your location');
      });
  }
}
