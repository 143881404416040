import storageAvailable from './storage_available';

function sanitiseSearchList(searchList) {
  const convertedSearchHistory = [];
  searchList.forEach((search) => {
    if (typeof search != "object") {
      const searchTerm = {
        search_string: search
      }
      convertedSearchHistory.push(searchTerm);
    } else {
      convertedSearchHistory.push(search);
    }
  });
  const uniqueSearchHistory = [...new Map(convertedSearchHistory.map(v => [v.search_string, v])).values()];
  return uniqueSearchHistory;
}

function searchIsNotValid(searchTerm) {
  return searchTerm.search_string === null || searchTerm.search_string.match(/^ *$/) !== null;
}

function replaceSearchHistory(searchList) {
  window.localStorage.setItem('last_search_list',JSON.stringify(searchList));
}

export function addSearchTermToSearchList(searchTerm) {
  if (searchIsNotValid(searchTerm) || !storageAvailable('localStorage')) {
    return false;
  }
  const searchList = JSON.parse(window.localStorage.getItem('last_search_list')) || [];

  if (searchList.length > 9) {
    searchList.pop();
  }

  return window.localStorage.setItem(
    'last_search_list',
    JSON.stringify([searchTerm, ...searchList]),
  );
}

export function getSearchHistory() {
  if (storageAvailable('localStorage')) {
    const searchHistory = JSON.parse(window.localStorage.getItem('last_search_list'));

    if (searchHistory !== null) {
      const convertedSearchHistory = sanitiseSearchList(searchHistory);
      replaceSearchHistory(convertedSearchHistory);
      return convertedSearchHistory;
    }
  }
  return null;
}
