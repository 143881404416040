import trackEvent from '../../helpers/gtm/eventPush';

const login = window._sr.session.loggedin;

const navItems = [
  {
    id: 'searchNav',
    action: 'search',
  },
  {
    id: 'myAccountNav',
    action: 'account',
  },
  {
    id: 'postAdNav',
    action: 'post_ad',
  },
  {
    id: 'aboutUsNav',
    action: 'about_us',
  },
  {
    id: 'browseNav',
    action: 'browse',
  },
  {
    id: 'infoAndAdviceNav',
    action: 'info_and_advice',
  },
  {
    id: 'helpNav',
    action: 'help',
  },
  {
    id: 'homeLogo',
    action: 'home',
  },
  {
    id: 'menuNav',
    action: 'my_account_dropdown',
  },
  {
    id: 'messageNav',
    action: 'messages',
  },
  {
    id: 'supportButtontNav',
    action: 'support',
  },
  {
    id: 'registerButtonNav',
    action: 'register',
  },
  {
    id: 'loginButtonNav',
    action: 'login',
  },
  {
    id: 'registerLinkNav',
    action: 'register',
  },
  {
    id: 'loginLinkNav',
    action: 'login',
  },
];

const subNavItems = [
  // search
  {
    id: 'mySearchNav',
    action: 'search_my_search',
  },
  {
    id: 'savedOfferedNav',
    action: 'search_saved_roomshare_ads',
  },
  {
    id: 'savedWantedNav',
    action: 'search_saved_flatmate_ads',
  },
  // post ad
  {
    id: 'optionsNav',
    action: 'post_ad_options',
  },
  {
    id: 'roomForRentNav',
    action: 'post_ad_room_offered',
  },
  {
    id: 'scpOptionsNav',
    action: 'post_ad_whole_property',
  },
  {
    id: 'roomWantedNav',
    action: 'post_ad_room_wanted',
  },
  {
    id: 'proAdvOptionsNav',
    action: 'post_ad_pro_ad_options',
  },
  // my account
  {
    id: 'messagesNav',
    action: 'account_messages',
  },
  {
    id: 'myViewingsNav',
    action: 'account_my_viewings',
  },
  {
    id: 'myListingsNav',
    action: 'account_my_ads',
  },
  {
    id: 'featuredAdsNav',
    action: 'account_featured_ads',
  },
  {
    id: 'savedNav',
    action: 'account_saved_ads',
  },
  {
    id: 'savedSearchesNav',
    action: 'account_saved_searches',
  },
  {
    id: 'whoIsInterestedNav',
    action: 'account_who_is_interested',
  },
  {
    id: 'editMyDetailsNav',
    action: 'account_edit_my_details',
  },
  {
    id: 'emailsAlertNav',
    action: 'account_email_alerts',
  },
  {
    id: 'upgradeNav',
    action: 'account_upgrade',
  },
  // About us
  {
    id: 'aboutSRNav',
    action: 'about_spareroom',
  },
  {
    id: 'whyUseSRNav',
    action: 'why_use_spareroom',
  },
  {
    id: 'howSRWorkNav',
    action: 'how_spareroom_works',
  },
  {
    id: 'howHeStartedNav',
    action: 'how_we_started',
  },
  {
    id: 'ourServicesNav',
    action: 'our_services',
  },
  {
    id: 'testimonialsNav',
    action: 'testimonials',
  },
  {
    id: 'tellAFriendNav',
    action: 'tell_a_friend',
  },
  {
    id: 'contactSRNav',
    action: 'contact_us',
  },
  // Info and advice
  {
    id: 'whatInfoNav',
    action: 'what_info_do_you_need',
  },
  {
    id: 'forSharersNav',
    action: 'for_sharers',
  },
  {
    id: 'forLandlordNav',
    action: 'for_landlords',
  },
  {
    id: 'whereToLiveNav',
    action: 'where_to_live',
  },
  {
    id: 'faqNav',
    action: 'faq',
  },
  {
    id: 'forRoommatesNav',
    action: 'for_roommates',
  },
  {
    id: 'forLandlordsNav',
    action: 'for_landlords',
  },
  {
    id: 'rentingInNYNav',
    action: 'renting_in_new_york',
  },
];

export function trackingMainNav() {
  navItems.forEach((navItem) => {
    const navElement = document.querySelector(`#${navItem.id}`);
    if (navElement) {
      navElement.addEventListener('click', () => {
        trackEvent({
          category: 'primary nav',
          action: `${navItem.action}`,
          label: `logged in: ${login}`,
        });
      });
    }
  });
}

export function trackingSubNav() {
  subNavItems.forEach((subNavItem) => {
    const navElement = document.querySelector(`#${subNavItem.id}`);
    if (navElement) {
      navElement.addEventListener('click', () => {
        trackEvent({
          category: 'secondary_nav',
          action: `${subNavItem.action}`,
          label: `logged in: ${login}`,
        });
      });
    }
  });
}
