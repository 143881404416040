const subNav = document.querySelector('.main-nav__sub-nav');
const navBar = document.querySelector('.nav-bar');

const setUp = () => {
  if (subNav) {
    navBar.insertAdjacentElement('afterend', subNav);
  }
  return true;
};

export default { setUp };
