export default function to_upper_case_first(string: string): string {
  if (typeof string !== 'string') {
    return string;
  }
  if (string.trim()) {
    const trimmed = string.trim();
    return trimmed.replace(trimmed[0], trimmed[0].toUpperCase());
  }
  return '';
}
