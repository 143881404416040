const signInLink = document.querySelector('#signInButton');
const logInPanel = document.querySelector('.login_box_panel');
const logInForm = document.querySelector('#loginform');

const deactivateDefault = (event) => event.preventDefault();

const hideLogInPanel = () => {
  logInPanel.classList.remove('login_box_panel--active');
  return signInLink.classList.remove(
    'authentication-links__sign-in-link--active',
  );
};

const showLogInPanel = () => {
  signInLink.classList.add('authentication-links__sign-in-link--active');
  logInPanel.classList.add('login_box_panel--active');
};

const deactivateLogInOnClick = (event) => {
  if (event.target == logInPanel || logInPanel.contains(event.target)) {
    return false;
  }
  hideLogInPanel();
  signInLink.addEventListener('click', activateLogInOnClick);
  return document.body.removeEventListener('click', deactivateLogInOnClick);
};

const activateLogInOnClick = (event) => {
  event.stopPropagation();
  showLogInPanel();
  signInLink.removeEventListener('click', activateLogInOnClick);
  return document.body.addEventListener('click', deactivateLogInOnClick, false);
};

const deactivateLogInOnHover = (event) => {
  const toElement = event.toElement || event.relatedTarget;
  if (
    event.target == logInPanel ||
    logInPanel.contains(event.target) ||
    event.target == signInLink ||
    toElement == signInLink ||
    toElement.classList.contains('authentication-links__icon')
  ) {
    return false;
  }
  hideLogInPanel();
  signInLink.addEventListener('mouseover', activateLogInOnHover);
  return document.body.removeEventListener('click', deactivateLogInOnHover);
};

const activateLogInOnHover = () => {
  showLogInPanel();
  document.body.addEventListener('mouseover', deactivateLogInOnHover, false);
};

const scrollToLogin = (event) => {
  event.preventDefault();
  logInForm.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
  logInForm.focus();
};

const attachEventListeners = () => {
  if (logInPanel.style.position) {
    return signInLink.addEventListener('click', scrollToLogin);
  }
  signInLink.addEventListener('click', deactivateDefault);
  signInLink.addEventListener('click', activateLogInOnClick);
  return signInLink.addEventListener('mouseover', activateLogInOnHover);
};

const setUp = () => {
  let bindEvents = false;
  if (!signInLink || !logInPanel) {
    return false;
  }
  bindEvents = 'login-box';
  return attachEventListeners();
};

export default { setUp };
