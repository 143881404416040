/**
 * // Simple string message
 * flashMessage.showSuccessMessage("some message to be flashed");
 *
 * // Optional object message
 * flashMessage.showSuccessMessage({
 *  heading: "A message heading",
 *  text: "Some message text",
 *  timeout: 0 // in seconds
 * });
 * In order to have a specific flash message be triggered automatically, two steps are required:
 *  1) the URL for which the message should be triggered must include a fragment identifier that
 *    comprises the word 'flash', the message type (error, info, success or warning), then a word
 *    to identify the specific message, e.g. `#flash-success-register`
 *  2) a corresponding entry in flashMessageMap, e.g.
 *    `flashMessageMap.success.register = 'Thank you, you have registered successfully'`
 * Please note that if using more than word to identify the specific message, e.g. `#flash-error-register-snafu`,
 * these words should be hyphenated in the URL fragment identifier but snaked-cased in `flashMessageMap`, e.g.
 * `flashMessageMap.error.register_snafu`
 * */
import React from 'react';
import ReactDOM from 'react-dom';
import get from '../../helpers/data_layer/get';
import predefinedMessages from './helpers/predefined_flash_messages';
import FlashMessages from './components/controller';

const init = (exposeMethods = true) => {
  const mountElement = document.getElementById('flash-messaging');
  if (mountElement) {
    ReactDOM.render(
      <FlashMessages
        exposeMethods={exposeMethods}
        predefinedMessages={predefinedMessages}
        siteData={get('site')}
        localisations={get('localisations')}
      />,
      mountElement,
    );
  } else {
    console.warn('Unable to mount flash messaging.');
  }
};

export default { init };
