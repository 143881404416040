import trackEvent from '../../../../helpers/gtm/eventPush';

function trackMessagingEvent({ action }) {
  return trackEvent({
    category: 'messaging',
    action,
    label: window.location.href,
  });
}

export function tracking(thestring: string) {
  const getThreadView = document.getElementById('manageThreadsForm');
  const getConversationView = document.getElementById('labels');

  if (thestring === 'edit-thread-note') {
    trackNotesCloseOverlay();
  }
  if (thestring === 'autoReplyOverlay' && getThreadView) {
    trackBulkReplyCloseOverlay();
  }
  if (thestring === 'autoReplyOverlay' && getConversationView) {
    trackAutoReplyCloseOverlay();
  }
  if (thestring === 'messageFilter') {
    trackFiltersCloseOverlay();
  }
  if (thestring === 'labelFilter') {
    trackLabelsFilterCloseOverlay();
  }
}

//----------------------------------------------------------------

// Sorting
// mobile and desktop

export function trackSortNewest() {
  return trackMessagingEvent({ action: 'sort_newest' });
}

export function trackSortOldest() {
  return trackMessagingEvent({ action: 'sort_oldest' });
}

export function trackSortName() {
  return trackMessagingEvent({ action: 'sort_name' });
}

// mobile
export function trackSortOpen() {
  return trackMessagingEvent({ action: 'sort_open' });
}
//----------------------------------------------------------------

// Autoreply

export function trackAutoReplyOpen() {
  return trackMessagingEvent({ action: 'autoreply_open' });
}

export function trackAutoReplySend(deleteCheckbox) {
  return trackEvent({
    category: 'messaging',
    action: 'autoreply_send',
    label: `delete=${deleteCheckbox}|${window.location.href}`,
  });
}

export function trackAutoReplyCloseX() {
  return trackMessagingEvent({ action: 'autoreply_close|x' });
}

export function trackAutoReplyCloseEscape() {
  return trackMessagingEvent({ action: 'autoreply_close|escape' });
}

export function trackAutoReplyCloseOverlay() {
  return trackMessagingEvent({ action: 'autoreply_close|overlay' });
}
//----------------------------------------------------------------

// Bulk reply

// Desktop and Mobile - Thread View

export function trackBulkReplyOpen() {
  return trackMessagingEvent({ action: 'bulkactions_reply_open' });
}

export function trackBulkReplySend(
  responseType,
  numberOfMessages,
  deleteCheckbox,
) {
  return trackEvent({
    category: 'messaging',
    action: 'bulkactions_reply_send',
    label: `${responseType}|${numberOfMessages}|delete=${deleteCheckbox}|${window.location.href}`,
  });
}

export function trackBulkReplyCloseX() {
  return trackMessagingEvent({ action: 'bulkactions_reply_close|x' });
}

export function trackBulkReplyCloseEscape() {
  return trackMessagingEvent({ action: 'bulkactions_reply_close|escape' });
}

export function trackBulkReplyCloseOverlay() {
  return trackMessagingEvent({ action: 'bulkactions_reply_close|overlay' });
}

export function trackBulkReplyCloseEscapeNotSent() {
  return trackMessagingEvent({
    action: 'bulkactions_reply_not_sent_pop_up_close|escape',
  });
}

export function trackBulkReplyCloseOverlayNotSent() {
  return trackMessagingEvent({
    action: 'bulkactions_reply_not_sent_pop_up_close|overlay',
  });
}

export function trackBulkReplyCloseNotSent() {
  return trackMessagingEvent({
    action: 'bulkactions_reply_not_sent_pop_up_close',
  });
}

export function trackBulkReplyPromoPanelOpen(numberOfMessages) {
  return trackEvent({
    category: 'banners',
    action: 'msg_inbox_bulkreply_promo_panel_show',
    label: `conversation_qty=${numberOfMessages}`,
  });
}

export function trackBulkReplyPromoPanelClose() {
  return trackEvent({
    category: 'banners',
    action: 'msg_inbox_bulkreply_promo_panel_close',
  });
}

//----------------------------------------------------------------

// filters desktop + mobile
export function trackFiltersApply() {
  return trackMessagingEvent({ action: 'filters_apply' });
}

// filters just desktop
export function trackFiltersResetClearLink() {
  return trackMessagingEvent({ action: 'filters_reset|clear_filters' });
}

// filters just mobile

export function trackFiltersOpenCountLink() {
  return trackMessagingEvent({ action: 'filters_open|count_link' });
}

export function trackFiltersResetClearAll() {
  return trackMessagingEvent({ action: 'filters_reset|clear_all' });
}

export function trackFiltersOpen() {
  return trackMessagingEvent({ action: 'filters_open' });
}

export function trackFiltersReset() {
  return trackMessagingEvent({ action: 'filters_reset|reset_filters' });
}

export function trackFiltersCloseX() {
  return trackMessagingEvent({ action: 'filters_close|x' });
}

export function trackFiltersCloseEscape() {
  return trackMessagingEvent({ action: 'filters_close|escape' });
}

export function trackFiltersCloseOverlay() {
  return trackMessagingEvent({ action: 'filters_close|overlay' });
}

// ----------------------------------------------------------------

// Labels

// Manage labels
// Thread View
//    Manage labels button - labels_manage_open
//    Manage labels popup close done - labels_manage_close|done
//    Manage labels popup close button - labels_manage_close|escape
//    Manage labels popup close overlay - labels_manage_close|overlay

export function trackManageLabelsOpen() {
  return trackMessagingEvent({ action: 'labels_manage_open' });
}

export function trackManageLabelsCloseDone() {
  return trackMessagingEvent({ action: 'labels_manage_close|done' });
}

export function trackManageLabelsCloseEscape() {
  return trackMessagingEvent({ action: 'labels_manage_close|escape' });
}

export function trackManageLabelsCloseOverlay() {
  return trackMessagingEvent({ action: 'labels_manage_close|overlay' });
}

// Add label
// Conversation View
//    Add label button - labels_add_open
//    Add label from pop up - labels_add_add
//    Create and add label from pop up - labels_add_create_attach
//    Manage labels link from filter modal - labels_add_link_manage_label
//    Add labels popup close x - labels_add_close|x
//    Add labels popup close button - labels_add_close|escape
//    Add labels popup close overlay - labels_add_close|overlay

export function trackAddLabelsOpen() {
  return trackMessagingEvent({ action: 'labels_add_open' });
}

export function trackAddLabels() {
  return trackMessagingEvent({ action: 'labels_add_add' });
}

export function trackAddLabelsCreate() {
  return trackMessagingEvent({ action: ' labels_add_create_attach' });
}

export function trackAddLabelsLinkManageLabel() {
  return trackMessagingEvent({ action: 'labels_add_link_manage_label' });
}

export function trackAddLabelsCloseX() {
  return trackMessagingEvent({ action: 'labels_add_close|x' });
}

export function trackAddLabelsCloseEscape() {
  return trackMessagingEvent({ action: 'labels_add_close|escape' });
}

export function trackAddLabelsCloseOverlay() {
  return trackMessagingEvent({ action: 'labels_add_close|overlay' });
}

// Remove label
// Thread View & Conversation View
//    Remove label - labels_remove
export function trackLabelsRemove() {
  return trackMessagingEvent({ action: 'labels_remove' });
}

// Labels filter button
// Thread View - mobile only
export function trackLabelsFilterOpen() {
  return trackMessagingEvent({ action: 'labels_filter_open' });
}

export function trackLabelsFilterCloseX() {
  return trackMessagingEvent({ action: 'labels_filter_close|x' });
}

export function trackLabelsFilterCloseOverlay() {
  return trackMessagingEvent({ action: 'labels_filter_close|overlay' });
}

// ----------------------------------------------------------------

// Bulk actions - labels

export function trackBulkLabelsOpen() {
  return trackMessagingEvent({ action: 'bulkactions_labels_open' });
}

export function trackBulkLabelsAdd() {
  return trackMessagingEvent({ action: 'bulkactions_labels_label-add' });
}

export function trackBulkLabelsAddCreate() {
  return trackMessagingEvent({ action: 'bulkactions_labels_create_attach' });
}

export function trackBulkLabelsCloseX() {
  return trackMessagingEvent({ action: 'bulkactions_labels_close|x' });
}

export function trackBulkLabelsCloseEscape() {
  return trackMessagingEvent({ action: 'bulkactions_labels_close|escape' });
}

export function trackBulkLabelsCloseOverlay() {
  return trackMessagingEvent({ action: 'bulkactions_labels_close|overlay' });
}

// ----------------------------------------------------------------

// Add Notes
//    Desktop and Mobile
//    Thread View & Conversation View:
//        Add/edit note button - notes_open
//        Add/edit note modal close X- notes_close|x
//        Add/edit note modal close escape - notes_close|escape
//        Add/edit note modal close by overlay- notes_close|overlay

export function trackNotesOpen() {
  return trackMessagingEvent({ action: 'notes_open' });
}

export function trackNotesCloseX() {
  return trackMessagingEvent({ action: 'notes_close|x' });
}

export function trackNotesCloseEscape() {
  return trackMessagingEvent({ action: 'notes_close|escape' });
}

export function trackNotesCloseOverlay() {
  return trackMessagingEvent({ action: 'notes_close|overlay' });
}

//----------------------------------------------------------------

// View ad links
//   Desktop and Mobile - Conversation View

export function trackViewAdResponder() {
  return trackMessagingEvent({ action: 'conversation_view_view_ad|responder' });
}

export function trackViewAdRecipient() {
  return trackMessagingEvent({ action: 'conversation_view_view_ad|recipient' });
}

// ------------------------------------------------------------

// Bulk delete and undelete
// Desktop and mobile - Thread View

export function trackBulkDelete() {
  return trackMessagingEvent({ action: 'bulkactions_messages_delete' });
}

export function trackBulkUndelete() {
  return trackMessagingEvent({ action: 'bulkactions_messages_undelete' });
}
