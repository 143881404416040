import storageAvailable from '../storage_available';

export default function processLocalGtmQueue() {
  if (storageAvailable('localStorage')) {
    const storedQueue = localStorage.getItem('gtm-queue');
    if (storedQueue) {
      const queue = JSON.parse(storedQueue);
      if (Array.isArray(queue)) {
        const { dataLayer } = window;
        if (dataLayer && dataLayer.push) {
          queue.forEach((gtmEvent) => dataLayer.push(gtmEvent));
          // empty the queue
          return localStorage.setItem('gtm-queue', JSON.stringify([]));
        }
      }
    }
  }
  return false;
}
