import toSnakeCase from '../../../../helpers/to_snake_case';

const { localStorage, _sr, dataLayer } = window;
const productMap = Object.assign(Object.create(null), {
  '5 days': '5_days',
  '7 days': '7_days',
  '10 days': '10_days',
  '14 days': '14_days',
  '28 days': '28_days',
  '30 days': '30_days',
  '1 week': '7_days',
  '2 weeks': '14_days',
  '4 weeks': '28_days',
  '3 months': '3_months',
  '6 months': '6_months',
  '12 months': '12_months',
  '1 year': '1_year',
  '1 semaine': '7_days',
  '4 semaines': '28_days',
  '2 mois': '2_months',
  '3 mois': '3_months',
  '1 an': '1_year',
});

let productTypeMatch;
let ecommerce;

function initialise() {
  let purchased = retrieveUpgradeFromLocalStorage();
  if (!purchased) {
    if (_sr.commerce.purchased.id) {
      purchased = _sr.commerce.purchased;
    }
  }
  if (purchased) {
    _sr.action.purchased = purchased;
    // checks if the description is a standard upgrade
    const regEx = /(Upgrade (?:\(inc Bold Advertising\)|\(inc Early Bird Access\)) for )([^\n\r]*)/;
    productTypeMatch = _sr.action.purchased.description.match(regEx);
    ecommerce = {
      currencyCode: _sr.site.currency,
      purchase: {
        actionField: {
          id: _sr.action.purchased.id,
          revenue: _sr.action.purchased.value,
          coupon: _sr.action.purchased.coupon,
        },
        products: [
          {
            name: _sr.action.purchased.description,
            category: constructCategoryString(),
            quantity: 1,
            price: _sr.action.purchased.value,
          },
        ],
      },
    };
    if (_sr.action.purchased.context) {
      ecommerce.purchase.products[0].dimension34 = _sr.action.purchased.context;
    }

    if (localStorage) {
      const purchaseCategory = _sr.action.purchased.category.toLowerCase();
      if (
        purchaseCategory == 'bold advertising' ||
        purchaseCategory == 'pro advertising'
      ) {
        if (!addMyNewestProfile()) {
          addLastViewedProfile();
        }
      }
      if (purchaseCategory == 'early bird') {
        addLastViewedProfile();
      }
    }
    _sr.ecommerce = ecommerce;
    dataLayer.push({
      action: {
        purchased: _sr.action.purchased,
      },
    });
    return dataLayer.push({ ecommerce: _sr.ecommerce });
  }
  return false;
}

function moreThanSixHours(then) {
  const diff = Date.now() - then;
  const hoursPassed = diff / 1000 / 60 / 60;
  if (hoursPassed > 6) {
    return true;
  }
  return false;
}

function retrieveUpgradeFromLocalStorage() {
  const storedPurchase = JSON.parse(localStorage.getItem('purchase'));
  if (storedPurchase) {
    if (storedPurchase.date && !moreThanSixHours(storedPurchase.date)) {
      const purchase = {
        id: storedPurchase.transactionId,
        value: storedPurchase.upgrade.value,
        description: storedPurchase.upgrade.description,
        category: storedPurchase.upgrade.category,
        coupon: null,
      };
      if (storedPurchase.coupon && storedPurchase.coupon.discount_percentage) {
        purchase.coupon = `${storedPurchase.coupon.discount_percentage}% discount from ${storedPurchase.coupon.promo_code}`;
      }
      if (storedPurchase.upgrade.category == 'Pro advertising') {
        purchase.department = storedPurchase.upgrade.department;
      }
      if (storedPurchase.upgrade.context) {
        purchase.context = storedPurchase.upgrade.context;
      }
      localStorage.removeItem('purchase');
      return purchase;
    }
    localStorage.removeItem('purchase');
  }
  return false;
}

function getUpgradeType(typeString) {
  const extraListings = typeString.match(/.+?(?= plus)/);
  if (extraListings) {
    return productMap[extraListings[extraListings.length - 1]];
  }
  return productMap[typeString];
}
function getProDescription() {
  if (
    _sr.action.purchased.category.match(
      /(Pro advertising|Multi ad advertising)/,
    )
  ) {
    return toSnakeCase(_sr.action.purchased.description);
  }
  return false;
}
function getLevelTwo() {
  if (
    _sr.action.purchased.category.match(
      /(Pro advertising|Multi ad advertising)/,
    )
  ) {
    return _sr.action.purchased.department;
  }
  return _sr.action.purchased.category;
}
function constructCategoryString() {
  const level = Object.create(null);
  level.one = 'product';
  level.two = toSnakeCase(getLevelTwo());
  level.three = productTypeMatch
    ? getUpgradeType(productTypeMatch[productTypeMatch.length - 1])
    : getProDescription();
  if (level.three) {
    return `${level.one}/${level.two}/${level.three}`;
  }
  return `${level.one}/${level.two}`;
}

function getPropertyType(profile) {
  return profile.property_type_more
    ? profile.property_type_more
    : `${profile.rooms_in_property} bed ${profile.property_type}`;
}
function getAccomodationType(profile) {
  return profile.scp == 'Y' ? 'whole_property' : 'room';
}
function getAreaList(areaList) {
  return areaList
    .split()
    .map(toSnakeCase)
    .join();
}

function getAvailability(profile) {
  return parseInt(profile.available_now, 10) ? 'yes' : 'no';
}
function getMostRecentlyViewed(profiles) {
  return profiles.sort((a, b) => b.date_stored - a.date_stored)[0];
}

function getLastViewedProfiles() {
  const lastViewedProfiles = [];
  const lastViewedProperty = JSON.parse(
    localStorage.getItem('last_viewed_property_profile'),
  );
  const lastViewedCustomer = JSON.parse(
    localStorage.getItem('last_viewed_customer_profile'),
  );
  if (lastViewedProperty) {
    lastViewedProfiles.push(lastViewedProperty);
  }
  if (lastViewedCustomer) {
    lastViewedProfiles.push(lastViewedCustomer);
  }
  return lastViewedProfiles;
}
function getLastViewedProfile() {
  const profiles = getLastViewedProfiles();
  let lastProfile;
  if (profiles.length > 1) {
    lastProfile = getMostRecentlyViewed(profiles).profile;
  } else if (profiles.length) {
    lastProfile = profiles[0].profile;
  }
  return lastProfile;
}

function addLastViewedProfile() {
  const profile = getLastViewedProfile();
  if (profile) {
    profile.quantity = 1;
    profile.price = _sr.action.purchased.value;
    profile.metric6 = 0;
    profile.metric7 = _sr.action.purchased.value;
    return ecommerce.purchase.products.push(profile);
  }
  return false;
}
function getMyNewestProfile() {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const myNewestProfile = JSON.parse(localStorage.getItem('newest-profile'));
  if (myNewestProfile && myNewestProfile.profile) {
    const { profile } = myNewestProfile;
    profile._daysOld = Math.floor(
      (Date.now() - Date.parse(profile.date_last_renewed)) / MS_PER_DAY,
    );
    profile._brand = 'free';
    if (profile.bold_ad == 'Y') {
      profile._brand = 'bold';
    } else if (profile.early_bird_required == 'Y') {
      profile._brand = 'early_bird';
    }
    profile._rooms =
      profile.ad_type == 'offered'
        ? profile.rooms_in_property
        : profile.no_of_rooms;
    profile._room_size =
      profile.ad_type == 'offered'
        ? getPropertyType(profile)
        : profile.room_size;
    profile._variant =
      profile.ad_type == 'offered'
        ? profile.advertiser_role
        : profile.gender_and_occupation.trim();
    if (profile.ad_type == 'offered') {
      profile._category = `${profile.ad_type}/${getAccomodationType(
        profile,
      )}/${toSnakeCase(profile.neighbourhood_name)}/${profile.postcode}`;
    } else {
      profile._category = `${profile.ad_type}/${toSnakeCase(
        profile.room_size,
      )}/${getAreaList(profile.area_list)}`;
    }
    return profile;
  }
  return false;
}
function addMyNewestProfile() {
  const profile = getMyNewestProfile();
  if (profile) {
    const productProfile = {
      name: profile.ad_title,
      id: profile.advert_id,
      brand: profile._brand,
      category: profile._category,
      variant: profile._variant,
      quantity: 1,
      price: _sr.action.purchased.value,
      dimension11: profile._daysOld,
      dimension12: getAvailability(profile),
      dimension13: profile._rooms,
      dimension14: profile._room_size,
      metric6: _sr.action.purchased.value,
      metric7: 0,
    };
    return ecommerce.purchase.products.push(productProfile);
  }
  return false;
}

export default initialise;
