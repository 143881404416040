class autocompleterSettings {
  constructor() {
    this.settings = {
      url: '/autocompleter.pl',
      queryString: {
        limit: 20,
        timestamp: Date.now(),
        campus: 'off',
        format: 'json',
      },
      settings: {
        minChars: 3,
        delay: 400,
        cache: true,
      },
    };
  }

  get currentSettings() {
    return this.settings;
  }
}

export default autocompleterSettings;
