function toggle_browse_lists(search_type_radios, browse_lists) {
  if (
    search_type_radios &&
    search_type_radios.length &&
    browse_lists &&
    browse_lists.length
  ) {
    search_type_radios.forEach((radio) => {
      radio.addEventListener('change', () => {
        browse_lists.forEach((list) =>
          list.classList.toggle('city_browse_links--hidden'),
        );
      });
    });
  }
}

function set_up_mobile_search_overlay_events() {
  /* Search dropdown overlay events */
  const search_form = document.querySelector('#searchForm');
  const search_type_radios =
    search_form &&
    search_form.querySelectorAll('input[type="radio"][name="flatshare_type"]');
  const browse_lists =
    search_form && search_form.querySelectorAll('.city_browse_links');
  toggle_browse_lists(search_type_radios, browse_lists);

  /* Browse nav dropdown overlay events - hijacking the search overlay */
  const search_nav = document.querySelector('#searchNav');
  const browse_nav = document.querySelector('#browseNav');
  if (browse_lists && browse_nav) {
    browse_nav.addEventListener('click', () => {
      search_form.classList.add('searchForm--browse-only');
    });
    if (search_nav) {
      search_nav.addEventListener('click', () => {
        search_form.classList.remove('searchForm--browse-only');
      });
    }
  }
}

function set_up_main_search_page_events() {
  /* search page (basic search) events */
  const search_form = document.querySelector('#pageSearchForm');
  const search_type_radios =
    search_form &&
    search_form.querySelectorAll('input[type="radio"][name="flatshare_type"]');
  const browse_lists =
    search_form && document.querySelectorAll('.city_browse_links');
  toggle_browse_lists(search_type_radios, browse_lists);
}

export default () => {
  set_up_mobile_search_overlay_events();
  set_up_main_search_page_events();
};
