const valInYearsOrMonths = (val) => {
  const valInYears = val / 12;

  if (val > 12 && val % 12 !== 0) {
    const yearCount = Math.floor(val / 12);
    const monthCount = val - 12 * yearCount;

    return `${yearCount} year and ${monthCount} months`;
  }

  // whole number when divided by 12, show in years
  if (Number.isInteger(valInYears)) {
    return `${valInYears} year${valInYears !== 1 ? 's' : ''}`;
  }
  // show in months
  return `${val} ${val === 1 ? 'month' : 'months'}`;
};

const formatMinToMaxTermText = (min, max) => {
  const minVal = valInYearsOrMonths(min);
  const maxVal = valInYearsOrMonths(max);

  if (minVal === maxVal) {
    return minVal;
  }

  // If either value in both years and months do not shorten
  if (/and/.test(minVal) || /and/.test(maxVal)) {
    return `${minVal} to ${maxVal}`;
  }

  // if both values in months or years show '6 to 9 months'
  if (
    (/year/.test(minVal) && /year/.test(maxVal)) ||
    (/month/.test(minVal) && /month/.test(maxVal))
  ) {
    return `${min % 12 === 0 ? min / 12 : min} to ${maxVal}`;
  }
  // otherwise '3 months to 2 years'
  return `${minVal} to ${maxVal}`;
};

export { valInYearsOrMonths, formatMinToMaxTermText };
