import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useApi from './useApi';
import Modal from '../../Modal/index';
import ProfileHeader from './components/ProfileHeader';
import UserDetails from './components/UserDetails';
import UserAdverts from './components/UserAdverts';
import UserType from './components/UserType';

const UserProfile = ({ localisations, site }) => {
  const [uuid, setUuid] = useState('');
  const { loading, error, data } = useApi(
    `/api/1.0/users/${uuid}/profiles`,
    uuid,
  );
  const [showDialog, setShowDialog] = useState(false);
  const [wantedAds, setWantedAds] = useState(null);
  const [offeredAds, setOfferedAds] = useState(null);
  const [adError, setAdError] = useState(null);

  const close = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    document
      .querySelectorAll('[data-profile-modal="user-profile"]')
      .forEach((element) => {
        element.addEventListener('click', (event) => {
          setShowDialog(true);
          const profile_thumbnail = event.currentTarget;
          setUuid(profile_thumbnail.getAttribute('data-uuid'));
        });
      });
  }, [uuid]);

  useEffect(() => {
    if (data?.user?.id) {
      const { id } = data.user;
      Promise.all([
        axios.get(`/${site.sub_dir}/api.pl?user_id=${id}&ad_type=wanted`),
        axios.get(`/${site.sub_dir}/api.pl?user_id=${id}`),
      ])
        .then(([flatmates, flatshares]) => {
          setOfferedAds(flatshares);
          setWantedAds(flatmates);
        })
        .catch((e) => {
          setAdError(localisations.profile.unable_to_display_ads);
          console.log(e);
        });
    }
    return () => setAdError(null);
  }, [data, localisations.profile.unable_to_display_ads, site.sub_dir]);

  return (
    <>
      {showDialog ? (
        <Modal
          close={close}
          showDialog={showDialog}
          modalLabelText="Info"
          id="profile-modal"
          fullBleed={true}
          wrapperClass="profile-modal"
        >
          {loading ? (
            <div className="profile-modal__loading">
              <span className="sr-only">{localisations.profile.loading}</span>
              <span aria-hidden="true">
                <i className="far fa-spinner fa-pulse"></i>
              </span>
            </div>
          ) : (
            <>
              <div
                className={`profile-viewer ${
                  data?.user?.has_full_access ? 'profile-viewer--upgraded' : ''
                }`}
              >
                <ProfileHeader
                  user={data?.user}
                  localisations={localisations.profile}
                  siteId={site.id}
                  upgraded={data?.user?.has_full_access}
                />
                <UserDetails
                  data={data}
                  error={error}
                  localisations={localisations.profile}
                  siteId={site.id}
                />
                <UserAdverts
                  offeredAds={offeredAds}
                  wantedAds={wantedAds}
                  error={adError}
                  user={data?.user}
                  adCount={
                    parseInt(wantedAds?.data?.count, 10) +
                    parseInt(offeredAds?.data?.count, 10)
                  }
                  site={site}
                  localisations={localisations.profile}
                />
                <div
                  className={`profile-viewer__user-types ${
                    wantedAds?.data?.count || offeredAds?.data?.count
                      ? 'profile-viewer__user-types--small'
                      : ''
                  }`}
                >
                  {data?.user?.user_type &&
                  offeredAds?.data &&
                  wantedAds?.data &&
                  !parseInt(offeredAds?.data?.count, 10) &&
                  !parseInt(wantedAds?.data?.count, 10) ? (
                    <UserType
                      localisations={localisations.profile}
                      userType={data.user.user_type}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default UserProfile;
