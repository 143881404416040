import React, { Component } from 'react';
import FlashMessage from './message';

class FlashMessages extends Component {
  constructor(props) {
    super(props);
    this.flashMessageMap = null;
    this.iconMap = {
      success: 'check-circle',
      error: 'exclamation-circle',
      warning: 'exclamation-triangle',
      info: 'info-circle',
    };
    this.adjustBuffer = this.adjustBuffer.bind(this);
    this.getFlashMessageMap = this.getFlashMessageMap.bind(this);
    this.autoFlashMessage = this.autoFlashMessage.bind(this);
    this.closeMessage = this.closeMessage.bind(this);
    this.state = {
      bufferHeightStyle: { height: 0 },
      messages: [],
      highestMessageID: 0,
    };
  }

  componentDidMount() {
    this.autoFlashMessage(this.props.hashFragment || null);
    if (this.props.exposeMethods) {
      window.flashMessage = {
        showSuccessMessage: flashMessage => this.showMessage('success', flashMessage),
        showErrorMessage: flashMessage => this.showMessage('error', flashMessage),
        showWarningMessage: flashMessage => this.showMessage('warning', flashMessage),
        showInfoMessage: flashMessage => this.showMessage('info', flashMessage),
        autoFlashMessage: this.autoFlashMessage,
      };
    }
  }

  autoFlashMessage(hashFragment = null) {
    hashFragment = (hashFragment || window.location.hash.slice(1)).split('-');
    const flashFragment = FlashMessages.processHashFragment(hashFragment);
    if (flashFragment) {
      const flashFragmentType = flashFragment[0];
      const flashFragmentName = flashFragment.length > 2
        ? flashFragment.slice(1).join('_')
        : flashFragment[1];
      this.getFlashMessageMap();
      const flashMessagesByType = this.flashMessageMap[flashFragmentType];
      if (flashMessagesByType) {
        const flashMessageContent = flashMessagesByType[flashFragmentName];
        if (flashMessageContent) {
          return this.showMessage(flashFragmentType, flashMessageContent);
        }
      }
    }
    return [];
  }

  getFlashMessageMap() {
    if (!this.flashMessageMap) {
      this.flashMessageMap = this.props.predefinedMessages.flashMessageMap(
        this.props.siteData,
        this.props.localisations
      );
    }
  }

  static processHashFragment(hash) {
    if (hash.length) {
      if (hash[0] == 'flash') {
        return hash.slice(1);
      }
      if (hash[0] == 'boosted' || hash[0] == 'renew') {
        return [hash[2] == 'failed' ? 'error' : hash[2], hash[0]];
      }
      if (hash[0] == 'subscribe') {
        return [hash[2], hash[0]];
      }
    }
    return null;
  }

  static getTimeOut(type, timeout) {
    if (timeout !== undefined) {
      return timeout * 1000;
    }
    return type == 'error' || type == 'warning' ? 0 : 9000;
  }

  adjustBuffer() {
    const wrapper = document.getElementById('flash-message-wrapper') || {};
    let increments = 0;
    const interval = setInterval(() => {
      increments += 100;
      if (increments <= 2500) {
        this.setState({
          bufferHeightStyle: {
            height: wrapper.offsetHeight ? `${wrapper.offsetHeight}px` : '0',
          },
        });
      } else {
        clearInterval(interval);
      }
    }, 10);
  }

  showMessage(type, message) {
    const content = typeof message == 'object' ? message : { text: message };
    const timeout = FlashMessages.getTimeOut(type, message.timeout);
    const timeoutCallback = timeout
      ? messageID => setTimeout(() => this.closeMessage(messageID), timeout)
      : () => null;
    let messageID;
    this.setState(
      (prevState) => {
        messageID = prevState.highestMessageID + 1;
        return {
          messages: [
            ...prevState.messages,
            {
              type,
              content,
              timeout,
              messageID,
            },
          ],
          highestMessageID: messageID,
        };
      },
      () => timeoutCallback(messageID),
    );
  }

  closeMessage(messageID) {
    this.setState(prevState => ({
      messages: prevState.messages.filter(
        message => message.messageID != messageID,
      ),
    }));
    this.adjustBuffer();
  }

  renderMessages() {
    return this.state.messages.length
      ? this.state.messages.map((message, index) => (
          <FlashMessage
            type={message.type}
            content={message.content}
            messageID={message.messageID}
            icon={this.iconMap[message.type]}
            adjustBuffer={this.adjustBuffer}
            closeMessage={this.closeMessage}
            key={index}
          />
      ))
      : null;
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={`flash-message${
            !this.props.siteData.mobile ? ' flash-message--desktop' : ''
          }`}
          id="flash-message-wrapper"
        >
          {this.renderMessages()}
        </div>
        <div
          className="flash-message__buffer"
          style={this.state.bufferHeightStyle}
        />
      </React.Fragment>
    );
  }
}

export default FlashMessages;
