import React from 'react';

const UserType = ({ userType, localisations }) => {
  return (
    <>
      {userType.have_a_share ? (
        <p className="profile-viewer__user-type">
          &quot;{localisations.have_a_share}&quot;
        </p>
      ) : null}
      {userType.looking_for_a_share ? (
        <p className="profile-viewer__user-type">
          &quot;{localisations.looking_for_a_share}&quot;
        </p>
      ) : null}
      {userType.make_a_share ? (
        <p className="profile-viewer__user-type">
          &quot;{localisations.make_a_share}&quot;
        </p>
      ) : null}
    </>
  );
};

export default UserType;
