import '../../sass/components/overlays.scss';
import get from '../helpers/data_layer/get';
import { tracking } from '../pages/messages/tracking/events';
/*
  This script controls the displays of three different types of modal: pop-up, drop-down and slide-in overlays.
  It is implemmented via data attributes:
    [data-overlay-target], used on an item (henceforth referred to as the 'trigger') that has an onClick callback that triggers display of a specified overlay:
      Value of this attribute: a string identifying the modal element to be shown;
    [data-overlay-name], used on the modal element itself (henceforth referred to as the 'target'):
      Value of this attribute: the target's identifier, corresponding with the identifier specified on the trigger
    [data-overlay-close], used on an element (such as a 'cross' icon) within a given overlay to close it on click:
      Value of this attribute: none
    [data-overlay-settings], used on the trigger:
      Value of this attribute: an object in string form containing settings options for the overlay.
      PLEASE NOTE that owing to the syntactical constraints of JSON, this value must use quotes in the following format:
        `data-overlay-settings='{"dropDownLink":"/contact.pl"}'`
      as you'd expect, the above can be used only with 'drop-down' type overlays. The following:
        `data-overlay-settings='{"mask":"on"}'` / `data-overlay-settings='{"mask":"off"}'`
      may be used with 'slide-in' or 'pop-up' type overlays, respectively. This setting controls whether a translucent overlay mask is displayed over the page but under the modal, thereby greying-out the rest of the page. For pop-up overlays, this is on by default; for slide-in overlays, it must be turned on. The final setting option currently supported is, for example:
        data-overlay-settings='{"fullscreen":"android"}'
      This causes modals to enter fullscreen mode on viewports of screen height less than or equal to 667 if the user agent matches the one specified, such as 'android' in the example.
*/

const overlayMask = document.getElementById('overlay-mask');
const classTriggerActive = 'overlay-trigger--active';
const classTargetActive = 'overlay-target--active';
const classMaskActive = 'overlay-mask--active';
const overlayClose = document.querySelectorAll('[data-overlay-close]');
const overlayTriggers = document.querySelectorAll('[data-overlay-target]');
const overlayMap = [];
const overlayNodes = [];
const validOverlayTypes = ['popUp', 'dropDown', 'slideIn'];
let fullScreenOverlayActive = false;
const mobileSite = get('site.mobile');

const mapOverlay = (overlayTrigger, overlayTarget) => ({
  trigger: overlayTrigger,
  target: overlayTarget,
  settings:
    JSON.parse(overlayTrigger.getAttribute('data-overlay-settings')) || {},
  triggerListeners: {},
  targetListeners: {},
});

const mapAllOverlays = () => {
  overlayTriggers.forEach((overlayTrigger) => {
    const overlayType = overlayTrigger.getAttribute('data-overlay-type');
    const target = overlayTrigger.getAttribute('data-overlay-target');
    if (validOverlayTypes.indexOf(overlayType) > -1) {
      const overlayTarget = document.querySelector(
        `[data-overlay-name=${target}]`,
      );
      overlayNodes.push(overlayTrigger, overlayTarget);
      const overlayTypeList = overlayMap[`${overlayType}Overlays`];
      const overlayObject = mapOverlay(overlayTrigger, overlayTarget);
      if (!overlayTypeList) {
        overlayMap[`${overlayType}Overlays`] = [overlayObject];
      } else {
        overlayTypeList.push(overlayObject);
      }
    } else {
      SR.log(`Invalid overlay type '${overlayType}' provided for ${target}.`);
    }
  });
};

const removeClass = (className) =>
  document
    .querySelectorAll(`.${className}`)
    .forEach((element) => element.classList.remove(className));

const overlayTracking = (event, overlay) => {
  if (overlay && overlay.id && event && event.target === overlayMask) {
    tracking(overlay.id);
  }
};

const setAriaAttributes = (button) => {
  if (!button || !button.hasAttribute('aria-expanded')) {
    return false;
  }
  const isExpanded = JSON.parse(button.getAttribute('aria-expanded'));
  return button.setAttribute('aria-expanded', !isExpanded);
};

const overlayOff = (event) => {
  const activeOverlay = document.querySelector(`.${classTargetActive}`);
  const activeTrigger = document.querySelector(`.${classTriggerActive}`);
  overlayTracking(event, activeOverlay);

  setAriaAttributes(activeTrigger);
  document.body.classList.remove('prevent-scroll');
  removeClass(classTriggerActive);
  removeClass(classTargetActive);
  removeClass(classMaskActive);
  if (
    fullScreenOverlayActive &&
    typeof document.webkitCancelFullScreen == 'function'
  ) {
    document.webkitCancelFullScreen();
    fullScreenOverlayActive = false;
  }
};

const overlayOn = (overlay) => {
  const activeOverlayType = overlay.trigger.getAttribute('data-overlay-type');
  overlay.trigger.classList.add(classTriggerActive);
  overlay.target.classList.add(classTargetActive);
  setAriaAttributes(overlay.trigger);

  if (activeOverlayType !== 'dropDown') {
    document.body.classList.add('prevent-scroll');
  }
  overlay.target.focus();
  if (
    overlay.settings.fullscreen &&
    typeof overlay.target.webkitRequestFullScreen == 'function'
  ) {
    fullScreenOverlayActive = displayFullscreen(overlay);
  }
};

const escListener = (event) => (event.keyCode === 27 ? overlayOff() : false);

const clickOutsideOverlayListener = (event) => {
  const activeOverlay = document.querySelector(`.${classTargetActive}`);
  const autocompleteActive = document.querySelector('.autocomplete-suggestion');
  const searchForm = document.getElementById('searchForm');
  if (
    activeOverlay &&
    !activeOverlay.getAttribute('data-overlay-type="popUp"') &&
    typeof document.contains == 'function'
  ) {
    if (
      searchForm &&
      searchForm.contains(event.target) &&
      activeOverlay.hasAttribute('data-overlay-name="search-box"')
    ) {
      return false;
    }
    if (searchForm && autocompleteActive) {
      return false;
    }
    if (
      overlayNodes.every((overlayNode) => !overlayNode.contains(event.target))
    ) {
      return overlayOff();
    }
  }
  return false;
};

const closeViaButton = (event) => {
  event.preventDefault();
  overlayOff();
};

const addCloseEvents = () => {
  overlayMask.addEventListener('click', (event) => {
    overlayOff(event);
  });
  document.addEventListener('keydown', escListener);
  if (overlayMap.dropDownOverlays || overlayMap.slideInOverlays) {
    document.addEventListener('click', clickOutsideOverlayListener);
  }
  if (overlayMap.popUpOverlays || overlayMap.slideInOverlays) {
    overlayClose.forEach((closeButton) =>
      closeButton.addEventListener('click', closeViaButton),
    );
  }
};

const addPopUpEvents = () => {
  overlayMap.popUpOverlays.forEach((popUpOverlay) => {
    popUpOverlay.triggerListeners.triggerPopUp = (event) => {
      closeViaButton(event);
      if (popUpOverlay.settings.mask != 'off') {
        overlayMask.classList.add(classMaskActive);
      }
      if (popUpOverlay.settings.class) {
        overlayMask.classList.add(popUpOverlay.settings.class);
      }
      overlayOn(popUpOverlay);
    };
    popUpOverlay.trigger.addEventListener(
      'click',
      popUpOverlay.triggerListeners.triggerPopUp,
    );
  });
};

const closeActiveDropDowns = (focusedTrigger) => {
  const dropDownTriggers = document.querySelectorAll(
    "[data-overlay-type='dropDown']",
  );
  dropDownTriggers.forEach((trigger) => {
    if (trigger !== focusedTrigger) {
      trigger.classList.remove('overlay-trigger--active');
      document
        .querySelector(`[data-overlay-name='${trigger.dataset.overlayTarget}']`)
        .classList.remove('overlay-target--active');
      if (trigger.hasAttribute('aria-expanded')) {
        trigger.setAttribute('aria-expanded', 'false');
      }
    }
  });
};

const dropDownKeypress = (overlay) => {
  closeActiveDropDowns(overlay.trigger);
  setAriaAttributes(overlay.trigger);
  overlay.trigger.classList.toggle(classTriggerActive);
  overlay.target.classList.toggle(classTargetActive);
};

const dropDownTargetLosesFocus = (event, overlay) => {
  if (
    !overlay.target.contains(event.relatedTarget) &&
    event.relatedTarget !== overlay.trigger
  ) {
    overlayOff();
  }
};

const addDropDownEvents = () => {
  overlayMap.dropDownOverlays.forEach((dropDownOverlay) => {
    dropDownOverlay.triggerListeners.triggerDropDown = () => {
      overlayOff();
      overlayOn(dropDownOverlay);
    };

    dropDownOverlay.triggerListeners.triggerDropDownKeypress = (event) =>
      dropDownKeypress(dropDownOverlay);

    dropDownOverlay.targetListeners.checkTargetHasFocus = (event) =>
      dropDownTargetLosesFocus(event, dropDownOverlay);

    const deactivateDropDown = (event) => {
      const toElement = event.toElement || event.relatedTarget;
      if (
        toElement &&
        !toElement.getAttribute('data-overlay-close-exempt') &&
        (!event.type == 'mouseout' ||
          !dropDownOverlay.target.contains(toElement))
      ) {
        overlayOff();
      }
    };
    dropDownOverlay.triggerListeners.deactivateDropDownTrigger = (event) =>
      deactivateDropDown(event);
    dropDownOverlay.targetListeners.deactivateDropDownTarget = (event) =>
      deactivateDropDown(event);
    if (!mobileSite) {
      dropDownOverlay.trigger.addEventListener(
        'mouseover',
        dropDownOverlay.triggerListeners.triggerDropDown,
      );
      dropDownOverlay.trigger.addEventListener(
        'mouseout',
        dropDownOverlay.triggerListeners.deactivateDropDownTrigger,
      );
      dropDownOverlay.target.addEventListener(
        'mouseout',
        dropDownOverlay.targetListeners.deactivateDropDownTarget,
      );
    }
    dropDownOverlay.trigger.addEventListener(
      'click',
      dropDownOverlay.triggerListeners.triggerDropDownKeypress,
    );
    dropDownOverlay.target.addEventListener(
      'focusout',
      dropDownOverlay.targetListeners.checkTargetHasFocus,
    );
  });
};

const addSlideInEvents = () => {
  overlayMap.slideInOverlays.forEach((slideInOverlay) => {
    slideInOverlay.triggerListeners.triggerSlideIn = (event) => {
      event.preventDefault();
      overlayOff();
      overlayOn(slideInOverlay);
      if (slideInOverlay.settings.mask === 'on') {
        overlayMask.classList.add(classMaskActive);
      }
      if (slideInOverlay.settings.class) {
        overlayMask.classList.add(slideInOverlay.settings.class);
      }
    };
    slideInOverlay.trigger.addEventListener(
      'click',
      slideInOverlay.triggerListeners.triggerSlideIn,
    );
  });
};

const addEvents = () => {
  if (overlayMap.popUpOverlays) {
    addPopUpEvents();
  }
  if (overlayMap.dropDownOverlays) {
    addDropDownEvents();
  }
  if (overlayMap.slideInOverlays) {
    addSlideInEvents();
  }
  if (overlayMask) {
    addCloseEvents();
  }
};

const deactivateDropDownListeners = () => {
  overlayMap.dropDownOverlays.forEach((dropDownOverlay) => {
    dropDownOverlay.trigger.removeEventListener(
      'mouseover',
      dropDownOverlay.triggerListeners.triggerDropDown,
    );
    dropDownOverlay.trigger.removeEventListener(
      'mouseout',
      dropDownOverlay.triggerListeners.deactivateDropDownTrigger,
    );
    dropDownOverlay.target.removeEventListener(
      'mouseout',
      dropDownOverlay.targetListeners.deactivateDropDownTarget,
    );
    dropDownOverlay.trigger.removeEventListener(
      'click',
      dropDownOverlay.triggerListeners.triggerDropDownKeypress,
    );
    dropDownOverlay.target.removeEventListener(
      'focusout',
      dropDownOverlay.targetListeners.checkTargetHasFocus,
    );
  });
};

const deactivateSlideInListeners = () => {
  overlayMap.slideInOverlays.forEach((slideInOverlay) => {
    slideInOverlay.trigger.removeEventListener(
      'click',
      slideInOverlay.triggerListeners.triggerSlideIn,
    );
  });
};

const deactivatePopUpListeners = () =>
  overlayMap.popUpOverlays.forEach((popUpOverlay) =>
    popUpOverlay.trigger.removeEventListener(
      'click',
      popUpOverlay.triggerListeners.triggerPopUp,
    ),
  );

const deactivateCloseListeners = () => {
  overlayMask.removeEventListener('click', overlayOff, false);
  document.removeEventListener('keydown', escListener);
  document.removeEventListener('click', clickOutsideOverlayListener);
  overlayClose.forEach((closeButton) =>
    closeButton.removeEventListener('click', closeViaButton),
  );
};

const deactivateAllEventListeners = () => {
  if (overlayMap.dropDownOverlays) {
    deactivateDropDownListeners();
  }
  if (overlayMap.slideInOverlays) {
    deactivateSlideInListeners();
  }
  if (overlayMap.popUpOverlays) {
    deactivatePopUpListeners();
  }
  if (window.triggerPopUp) {
    const reportDeactivation = () =>
      SR.log(
        'A pop-up overlay would have been launched, but overlays have been deactivated',
      );
    window.triggerPopUp = reportDeactivation;
  }
  deactivateCloseListeners();
  return SR.log('Overlays deactivated');
};

const displayFullscreen = function(overlay) {
  const isFullscreenScenario = overlay.settings.fullscreen
    .split(',')
    .some((context) => {
      const regex = new RegExp(context);
      return (
        window.innerHeight <= 667 &&
        regex.test(navigator.userAgent.toLowerCase())
      );
    });
  if (isFullscreenScenario) {
    overlay.target.webkitRequestFullScreen();
    return true;
  }
  return false;
};

const init = () => {
  mapAllOverlays();
  addEvents();
};

window.deactivateOverlays = () => deactivateAllEventListeners();
window.reactivateOverlays = () => addEvents();

export default { init };
