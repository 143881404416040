import get from '../../helpers/data_layer/get';

const { new_message_count } = get('customer');

const init = function() {
  const menuEmailIndicator = document.querySelectorAll('.new-email');
  if (new_message_count) {
    menuEmailIndicator.forEach(item => item.classList.add('new-email--show'));
  }
};

export default { init };
