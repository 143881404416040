import React from 'react';

function updateImageVersion(src) {
  if (!src) {
    return false;
  }

  const largeVersion = src.replace('/unmodified/', '/cw300h300/');
  return largeVersion;
}

const ProfileHeader = ({ user, upgraded, localisations, siteId }) => {
  return (
    <div className="profile-header">
      <div
        className={`profile-header__profile-img ${
          user?.verified ? 'verified' : ''
        }`}
      >
        <span className="profile-header__image-wrap">
          <img
            alt={`Profile image for ${user?.first_name}`}
            src={
              updateImageVersion(user?.profile_photo?.url) ||
              '/img/spareroom/v4/icons/profilepic_unisex.gif'
            }
            width="200"
            className="profile-header__image"
          />
        </span>
      </div>
      <h4 className="profile-header__name">
        {user?.company ? user?.company.name : user?.first_name}
      </h4>
      {siteId === 21 ? (
        upgraded ? (
          <span className="profile-header__status profile-header__status--premium">
            membre premium
          </span>
        ) : (
          <span className="profile-header__status">membre basique</span>
        )
      ) : (
        <>
          <span className="profile-header__item">
            {user?.advertiser_role_display}
          </span>
          {user?.advertiser_role_display && upgraded ? (
            <span className="profile-header__divider">|</span>
          ) : null}
          {upgraded ? (
            <span className="profile-header__item profile-header__item--upgraded">
              {localisations.upgraded}
            </span>
          ) : null}
          {user?.verified && (user?.advertiser_role_display || upgraded) ? (
            <span className="profile-header__divider">|</span>
          ) : null}
          {user?.verified ? (
            <span className="profile-header__item profile-header__item--verified">
              {localisations.verified}
            </span>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ProfileHeader;
