import React, { useEffect, useState } from 'react';
import { createProfile } from '../helpers/format_data';
import to_upper_case_first from '../../../../helpers/to_upper_case_first';

const UserDetails = ({ data, error, localisations, siteId }) => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (data) {
      setProfile(createProfile(data, siteId));
    }
  }, [data, siteId]);

  return (
    <>
      {profile ? (
        <ul className="profile-viewer__data-list">
          {Object.entries(profile).map(([key, value]) => (
            <li
              key={key}
              className={`profile-viewer__list-item ${
                !value || value === 'not_disclosed'
                  ? 'profile-viewer__list-item--unknown'
                  : ''
              }`}
            >
              {localisations[key]}{' '}
              <span className="profile-viewer__data-value">
                {value || value !== 'not_disclosed'
                  ? to_upper_case_first(localisations[value] || value)
                  : ''}
              </span>
            </li>
          ))}
        </ul>
      ) : null}
      {error ? (
        <p className="profile-viewer__error">
          {error.response?.status === 404
            ? localisations.no_profile_found
            : localisations.unknown_error}
        </p>
      ) : null}
    </>
  );
};

export default UserDetails;
