/**
 * Just a wrapper for 'js/v.
 * This should be replaced by a react component asap.
 * */

const init = () => {
  if (window.SR) {
    const { facebookConnect } = window.SR;
    if (
      typeof facebookConnect == 'object' &&
      typeof facebookConnect.init == 'function'
    ) {
      return facebookConnect.init();
    }
    console.error('Old facebookConnect has not been initialised');
  }
  console.info('New facebookConnect will be initialized');
  return null;
};

export default init;
