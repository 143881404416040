import React from 'react';
import ReactDOM from 'react-dom';
import { fetchData } from '../../helpers/base_data_fetcher';
import '../../../sass/components/profile_viewer.scss';
import UserProfile from './profile_modal/Index';

const init = function() {
  const mountElement = document.getElementById('profileViewer');

  if (mountElement) {
    Promise.all([fetchData('localisations'), fetchData('site')]).then(
      ([localisations, site]) => {
        ReactDOM.render(
          <UserProfile localisations={localisations} site={site} />,
          mountElement,
        );
      },
    );
  }
};

export default { init };
