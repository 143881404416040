import storageAvailable from '../../helpers/storage_available';

function trackReEngagement() {
  if (storageAvailable('localStorage')) {
    const reEngaged = JSON.parse(localStorage.getItem('re-engaged'));
    if (reEngaged) {
      const MS_PER_MINUTE = 1000 * 60;
      const minutesOld = Math.floor(
        (Date.now() - reEngaged.date) / MS_PER_MINUTE,
      );
      if (minutesOld < 60 && window.dataLayer) {
        window.dataLayer.push({
          affiliate: reEngaged.affiliate,
          event: 're-engaged',
        });
        return localStorage.removeItem('re-engaged');
      }
      return false;
    }
    return false;
  }
  return false;
}

export default trackReEngagement;
