import axios from 'axios';
import { useEffect, useState } from 'react';

const useApi = (url, uuid) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (uuid) {
      axios
        .get(url, { cancelToken: source.token })
        .then((response) => {
          setData(response.data.data);
          setLoading(false);
        })
        .catch((e) => {
          setError(e);
          console.log(e.message);
          setLoading(false);
        });
    }
    return () => {
      setData(null);
      setError(null);
      setLoading(true);
      source.cancel();
    };
  }, [url, uuid]);

  return { loading, error, data };
};

export default useApi;
