import storageAvailable from '../../helpers/storage_available';

function trackRegistration() {
  if (storageAvailable('localStorage')) {
    const MS_PER_MINUTE = 1000 * 60;
    const registered = JSON.parse(localStorage.getItem('registered'));
    if (registered) {
      const minutesOld = Math.floor(
        (Date.now() - registered.date) / MS_PER_MINUTE,
      );
      if (minutesOld < 60 && window.dataLayer) {
        window.dataLayer.push({
          affiliate: registered.affiliate,
          event: 'registered',
        });
        return localStorage.removeItem('registered');
      }
      return false;
    }
    return false;
  }
  return false;
}

export default trackRegistration;
