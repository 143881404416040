import Cookies from 'js-cookie';
import get from '../../helpers/data_layer/get';

export default function() {
  const choices = document.querySelectorAll('.locale-choice__radio');

  const choice_form = document.querySelector('#country_id_form');

  const url = new URL(window.location);
  const locale_choice = url.searchParams.get('locale_choice');
  const siteId = get('site.id');
  const domain = get('site.domain');

  const country_id = siteId == 12 ? 2 : siteId;

  let selected_country_id;

  const updateLocale = function() {
    const options = { expires: 30 };
    if (domain) {
      options.domain = domain;
    }
    const stateObj = { locale_choice };
    window.history.replaceState(stateObj, '', window.location.pathname);
    Cookies.set('country_id', locale_choice, options);
  };

  if (choice_form) {
    const addEvents = () => choice_form.addEventListener('submit', submitForm);
    
    const submitForm = function(event) {
      event.preventDefault();
      let return_url;
      choices.forEach((choice) => {
        if (choice.checked) {
          selected_country_id = choice.value;
          return_url = choice.getAttribute('data-website');
        }
      });
      if (selected_country_id == country_id) {
        document.getElementById('overlay-mask').click();
      } else {
        window.location.assign(
          `${return_url}?locale_choice=${selected_country_id}`,
        );
      }
    };
    if (locale_choice == country_id) {
      updateLocale();
    }
    addEvents();
  }
}
