import flashMessage from '../apps/flash_message/helpers/get_flash_message';

/*
  Checks whether storage is available -
  https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
*/
function storageAvailable(type) {
  try {
    const storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    const messagesOnScreen = document.getElementsByClassName(
      'flash-message__message',
    );
    if (messagesOnScreen.length == 0) {
      flashMessage.showErrorMessage({
        heading: 'Warning',
        text:
          'It appears that you have cookies/local storage disabled which prevents the site from functioning correctly. Please enable them and try again.',
      });
    }
    return (
      e instanceof DOMException
      // everything except Firefox
      && (e.code === 22
        // Firefox
        || e.code === 1014
        // test name field too, because code might not be present
        // everything except Firefox
        || e.name === 'QuotaExceededError'
        // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      // acknowledge QuotaExceededError only if there's something already stored
      && storage.length !== 0
    );
  }
}

export default storageAvailable;
