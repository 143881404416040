import Cookies from 'js-cookie';

const setCookieAndDismissBanner = (cookie_name, days_before_expiry) => {
  const closeBtn = document.getElementById('promoBannerClose');
  const banner = document.getElementById('promoBanner');

  if (!banner) {
    return;
  }

  closeBtn.addEventListener('click', () => {
    Cookies.set(cookie_name, '1', {
      expires: days_before_expiry,
    });
    banner.remove();
  });
};

export default setCookieAndDismissBanner;
