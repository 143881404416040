import Autocompleter from '../services/autocompleter/autocompleter';
import { getSearchHistory, addSearchTermToSearchList } from './store_searches';

export default function setUpSearchBoxWithAutocomplete(searchInput, searchForm) {
  const searchHistory = getSearchHistory();

  if (searchInput && searchForm) {
    searchInput.autocomplete = 'off';
    Autocompleter.init({
      input: searchInput,
      form: searchForm,
      queryString: {},
      searchHistory,
    });
    searchForm.addEventListener('submit', () => {
      const searchTerm = {
        search_string: searchInput.value
      };

      addSearchTermToSearchList(searchTerm);
    });
  }
}