const flashMessageMap = (siteData, localisations) => ({
  error: {
    boosted: 'Boost is not available',
    renew: 'Renew is not available',
    subscribe: 'Something is wrong with the email you provided.',
    upgrade_error: {
      heading: localisations.upgrade.upgrade_error,
      text: `${localisations.upgrade.upgrade_error_msg} ${siteData.phone_number}.`,
      timeout: 0,
    },
    upgrade_failure: {
      heading: localisations.upgrade.upgrade_failure,
      text: localisations.upgrade.payment_not_authorised,
    },
  },
  info: {
    competitions:
      "You've already entered all our currently available competitions!",
  },
  success: {
    boosted: {
      heading: 'Your ad has been Boosted!',
      text:
        "It's been moved up the search results to get more views. Please allow up to 20 minutes for this to go live.",
    },
    competitions: "You've entered all available competitions successfully!",
    competition_coupon_redeemed: 'Thanks, your extra entries have been added.',
    contact:
      'Thank you for contacting us. We will be in touch as soon as possible.',
    featured_ad_payment: {
      heading: 'Your Featured Ad payment was successful.',
      timeout: 0,
    },
    register: 'Thank you, you have registered successfully',
    renew: localisations.upgrade.your_ad_renewed,
    subscribe: "You're successfully signed up for email notifications.",
    ticket_payment: {
      heading: 'You have booked and paid for your ticket.',
      text:
        'To gain access to the event, you will just need to provide the email address that you booked with.',
      timeout: 0,
    },
    upgrade: {
      heading: localisations.upgrade.upgrade_applied,
      text: localisations.upgrade.upgrade_applied_msg,
      timeout: 0,
    },
    deactivate: {
      heading: localisations.my_ads.ad_deactivated,
    },
    deactivate_with_feedback: {
      heading: localisations.my_ads.thank_you_for_feedback,
      text: localisations.my_ads.ad_deactivated,
    },
    payment_method_updated: {
      heading: 'Card details updated',
      text: 'You have updated your default card details for subscriptions',
      timeout: 0,
    },
    viewing: {
      heading: 'Your viewing has been created.',
      text: 'A message has been sent for confirmation.',
    },
    viewing_cancelled: 'This viewing has been cancelled',
    viewing_edited: 'Your viewing has been updated',
    viewing_confirmed: 'Viewing has been confirmed',
  },
  warning: {},
});

export default { flashMessageMap };
