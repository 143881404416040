import React from 'react';
import ReactDOM from 'react-dom';
import get from '../../helpers/data_layer/get';
import { isAndroid, isIOS } from '../../helpers/device_checks';

const staticFilesServer = get('site.static_files_server');
const country = get('site.country');
const playStoreLink = `https://app.appsflyer.com/com.spareroom.spareroom${
  country === 'GB' ? 'uk' : 'us'
}?pid=web&amp;c=app_download&amp;af_channel=web`;
const mountElement = document.getElementById('nav_menu_app_links');

const AppBanner = (props) => (
  <a className={props.aClass} href={props.storeLink}>
    <img
      src={`${staticFilesServer}/${props.img}`}
      alt={props.alt}
      width={props.width}
      height={props.height}
      className={props.imgClass}
    />
  </a>
);

const AndroidBanner = () => (
  <AppBanner
    aClass="nav-menu__app-link"
    storeLink={playStoreLink}
    img="images/spareroom/apps/icon-google-play@2x.png"
    alt="Get it on Google Play"
    width="136"
    height="40"
    imgClass="nav-menu__app-buttons-image"
  />
);

const IosBanner = () => (
  <AppBanner
    aClass="nav-menu__app-link"
    storeLink="https://itunes.apple.com/app/apple-store/id400181250?pt=450238&ct=uk-desktop-universal-footer&at=1001lnJ5&mt=8&ls=1"
    img="images/spareroom/apps/icon-app-store@2x.png"
    alt="Download on the App Store"
    width="120"
    height="40"
    imgClass="nav-menu__app-buttons-image"
  />
);

const Banners = () =>
  isAndroid() ? (
    <AndroidBanner />
  ) : isIOS() ? (
    <IosBanner />
  ) : (
    <>
      <IosBanner />
      <AndroidBanner />
    </>
  );

const setUp = () => {
  if (mountElement) {
    ReactDOM.render(<Banners />, mountElement);
  }
};

export default { setUp };
