// both past_date and now_date must be a JavaScript Date instance
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
// documentation for distanceInWordsStrict(): https://date-fns.org/v1.29.0/docs/distanceInWordsStrict
import distanceInWordsStrict from 'date-fns/formatDistanceStrict/index';

import { fr } from 'date-fns/locale';

const isWithinLastHour = (distanceString) =>
  distanceString.includes('seconde') || distanceString.includes('minute');

const isWithinLastDay = (distanceString) => distanceString.includes('heure');

const specifyWithinLastWeek = (distanceString, daysAgo) =>
  daysAgo >= 7 ? 'Il y a 1 semaine' : `Il y a ${distanceString}`;

const specifyWithinLastMonth = (distanceString) => {
  const daysAgo = parseInt(distanceString.substring(0, 2), 10);
  return daysAgo <= 13
    ? specifyWithinLastWeek(distanceString, daysAgo)
    : `Il y a ${Math.floor(daysAgo / 7)} semaines`;
};

const isLongerThanAMonthAgo = (distanceString) =>
  distanceString.includes('mois') || distanceString.includes('an');

const distanceBetweenDatesInWords = function(past_date, now_date) {
  // Is this really the right round method (the default changed with V2, so this has been highlighted)
  const distanceString = distanceInWordsStrict(past_date, now_date, {
    roundingMethod: 'floor',
    locale: fr,
  });
  if (isWithinLastHour(distanceString)) {
    return "A l'instant";
  }
  if (
    isWithinLastDay(distanceString) ||
    isLongerThanAMonthAgo(distanceString)
  ) {
    return `${distanceString}`;
  }
  return distanceString.includes('jour')
    ? specifyWithinLastMonth(distanceString)
    : 'Inconnu';
};

export default distanceBetweenDatesInWords;
