import flashMessage from '../flash_message';

const isFlashMessageAvailable = () => {
  if (!window.flashMessage) {
    flashMessage.init();
  }
  return window.flashMessage;
};

/*
The getter is being used everywhere in the code expecting the object of exposed methods
Until we refactor the logic of the flashMessage component, we need to return an object.
*/
const getFlashMessage = isFlashMessageAvailable();

export default getFlashMessage;
