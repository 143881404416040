function isIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

function isMobile() {
  return /Mobi|Tablet/i.test(navigator.userAgent);
}

export { isIOS, isAndroid, isMobile };
