// both past_date and now_date must be a JavaScript Date instance
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
// documentation for distanceInWordsStrict(): https://date-fns.org/v1.29.0/docs/distanceInWordsStrict
import distanceInWordsStrict from 'date-fns/formatDistanceStrict/index';

const isWithinLastHour = (distanceString) =>
  distanceString.includes('second') || distanceString.includes('minute');

const isWithinLastDay = (distanceString) => distanceString.includes('hour');

const specifyWithinLastWeek = (distanceString, daysAgo) =>
  daysAgo >= 7 ? '1 week ago' : `${distanceString} ago`;

const specifyWithinLastMonth = (distanceString) => {
  const daysAgo = parseInt(distanceString.substring(0, 2), 10);
  return daysAgo <= 13
    ? specifyWithinLastWeek(distanceString, daysAgo)
    : `${Math.floor(daysAgo / 7)} weeks ago`;
};

const isLongerThanAMonthAgo = (distanceString) =>
  distanceString.includes('month') || distanceString.includes('year');

const distanceBetweenDatesInWords = function(past_date, now_date) {
  // Is this really the right round method (the default changed with V2, so this has been highlighted)
  const distanceString = distanceInWordsStrict(past_date, now_date, {
    roundingMethod: 'floor',
  });
  if (isWithinLastHour(distanceString)) {
    return 'Just now';
  }
  if (
    isWithinLastDay(distanceString) ||
    isLongerThanAMonthAgo(distanceString)
  ) {
    return `${distanceString} ago`;
  }
  return distanceString.includes('day')
    ? specifyWithinLastMonth(distanceString)
    : 'Unknown';
};

export default distanceBetweenDatesInWords;
