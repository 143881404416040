import React from 'react';
import { getMonth } from '../helpers/format_data';

function getPriceRange(advert, symbol, localisations) {
  if (advert.equivalent_room_price_from) {
    return `${symbol}${advert.min_rent} `;
  }
  if (advert.max_rent && advert.max_rent !== advert.min_rent) {
    return `${localisations.from} ${symbol}${advert.min_rent} ${localisations.to} ${symbol}${advert.max_rent}`;
  }
  return `${symbol}${advert.min_rent}`;
}

const UserAdverts = ({
  offeredAds,
  wantedAds,
  error,
  user,
  adCount,
  site,
  localisations,
}) => {
  const offeredResults = offeredAds?.data?.results;
  const wantedResults = wantedAds?.data?.results;
  const offeredAdSlice = wantedResults?.length > 0 ? 2 : 3;

  return (
    <div
      className={`profile-viewer__adverts ${
        !adCount ? 'profile-viewer__adverts--none' : ''
      }`}
    >
      {adCount > 0 ? (
        <h4 className="profile-viewer__advert-heading">
          {localisations.ads_from_user}{' '}
          {user?.company ? user?.company.name : user?.first_name}
        </h4>
      ) : null}
      <ul className="profile-viewer__listings">
        {offeredResults?.length > 0
          ? offeredResults.slice(0, offeredAdSlice).map((advert) => (
              <li key={advert.advert_id}>
                <a href={`/${advert.advert_id}`}>
                  {advert.ad_title}
                  <span className="profile-viewer__ad-details">
                    {getMonth(
                      advert.available_from,
                      'do MMM',
                      site.id,
                      localisations.available,
                    ) || localisations.available_now}
                    {site.id === 21 ? ' ' : ''}: {advert.neighbourhood_name}{' '}
                    {getPriceRange(advert, site.currency_symbol, localisations)}{' '}
                    {advert.per === 'pcm'
                      ? localisations.pcm
                      : localisations.pw}
                  </span>
                </a>
              </li>
            ))
          : null}
        {wantedResults?.length > 0
          ? wantedResults.map((advert) => (
              <li key={advert.advert_id}>
                <a href={`/${advert.advert_id}`}>
                  {advert.ad_title}
                  <span className="profile-viewer__ad-details">
                    {getMonth(advert.available_from, 'MMMM', site.id) ||
                      localisations.available_now}
                    {site.id === 21 ? ' ' : ''}: {advert.summarised_location}{' '}
                    {site.currency_symbol}
                    {advert.combined_budget}{' '}
                    {advert.per === 'pcm'
                      ? localisations.pcm
                      : localisations.pw}
                  </span>
                </a>
              </li>
            ))
          : null}
      </ul>
      {error ? <p className="profile-viewer__error">{error}</p> : null}
      {adCount > 3 ? (
        <a className="profile-viewer__advert-link" href={`/u${user?.id}`}>
          {localisations.see_all} {offeredAds?.data.count}{' '}
          {localisations.ads_from_this_user}
        </a>
      ) : null}
    </div>
  );
};

export default UserAdverts;
