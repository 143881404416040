import axios from 'axios';
import { fetchData } from './base_data_fetcher';

declare global {
  interface Window {
    _sr: {
      customer: {
        timezone: string;
      };
    };
  }
}

async function setProfileTimezone(uuid: string) {
  try {
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    window._sr.customer.timezone = time_zone;
    const response = await axios.patch(`/api/1.0/users/${uuid}/profiles`, {
      time_zone,
    });
  } catch (error) {
    if (error instanceof TypeError) {
      console.log('Not supported in this browser.', error);
    } else {
      console.log('An unexpected error occurred:', error);
    }
  }
}

async function checkTimezone() {
  const [session, customer] = await Promise.all([
    fetchData('session'),
    fetchData('customer'),
  ]);
  if (!session.loggedin || customer.timezone) {
    return;
  }

  try {
    const response = await axios.get(
      `/api/1.0/users/${customer.uuid}/profiles`,
    );
    if (response.data.data.user && !response.data.data.user.time_zone) {
      setProfileTimezone(customer.uuid);
    }
  } catch (error) {
    console.log('User profile not available', error);
  }
}

export { checkTimezone, setProfileTimezone };
