/*
 * Function to get the variant for the given testId.
 * Returns the value of the variant e.g '1' or null
 * Example uasge: const variant = getActiveTestVariant('my_ab_test');
 */

const getActiveTestVariant = (testId) => {
  const active_experiments = window._sr?.ab_tests?.active_experiments;
  if (active_experiments && active_experiments[testId] !== undefined) {
    const { variant } = active_experiments[testId];
    return variant;
  }
  return null; // Test not found or not active
};

/*
 * Function to log info about active A/B tests in the console.
 */

const logActiveTests = () => {
  const active_experiments = window._sr?.ab_tests?.active_experiments;

  if (active_experiments) {
    const tests = Object.keys(active_experiments).map((testName) => {
      const activeExperiment = active_experiments[testName];
      return {
        ID: activeExperiment.id,
        TestName: testName,
        Variant: activeExperiment.variant,
      };
    });

    if (tests.length > 0) {
      console.info('🧪 Active A/B tests:');
      tests.forEach((test) => {
        console.groupCollapsed(`Test: ${test.TestName}`);
        console.log(`ID: ${test.ID}`);
        console.log(`Variant: ${test.Variant}`);
        console.groupEnd();
      });
    } else {
      console.info('🧪 No active A/B tests.');
    }
  }
};
export { getActiveTestVariant, logActiveTests };
