import get from './data_layer/get';

const wrapResponseInPromise = (data, compositeResponse = false) =>
  new Promise((resolve, reject) => {
    if (
      (!compositeResponse && typeof data != 'undefined') ||
      (compositeResponse &&
        Object.values(data).every((value) => typeof value == 'object'))
    ) {
      return resolve(data);
    }
    return reject(new Error('The requested data is not available'));
  });

const fetchData = (string) => wrapResponseInPromise(get(string));

export { wrapResponseInPromise, fetchData };
