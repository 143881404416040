import React, { Component } from 'react';

class FlashMessage extends Component {
  constructor(props) {
    super(props);
    this.index = this.props.index;
    this.state = {
      visibleClass: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ visibleClass: ' flash-message__message--visible' });
      this.props.adjustBuffer();
    }, 1000);
  }

  render() {
    return (
      <div
        className={`flash-message__message flash-message__message--${this.props.type}${this.state.visibleClass}`}
      >
        <div className="flash-message__content">
          <a
            className="flash-message__close"
            onClick={() => this.props.closeMessage(this.props.messageID)}
          >
            <i className="far fa-times" />
          </a>
          <i className={`flash-message__icon fas fa-${this.props.icon}`} />
          <MessageText content={this.props.content} />
        </div>
        <hr className="flash-message__border" />
      </div>
    );
  }
}

const MessageText = (props) =>
  props.content.heading ? (
    <p className="flash-message__text">
      <strong className="flash-message__heading">
        {props.content.heading}
      </strong>
      <span>{props.content.text}</span>
    </p>
  ) : (
    <p className="flash-message__text">{props.content.text}</p>
  );

export default FlashMessage;
